import { makeObservable, observable, computed, action } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as payments from '../communicator/payments';

class StorePayments {
    listMethods = []
    listIssuers = []
    paymentUrl = '';

    paymentIssuer = '';
    paymentMethod = '';
    paymentAmount = '1';
    paymentName = '';
    paymentEmail = '';
    paymentDescription = '';
    paymentSku = '';

    constructor() {
        makeObservable(this, {
            paymentUrl: observable,
            paymentIssuer: observable,
            paymentMethod: observable,
            paymentAmount: observable,
            paymentName: observable,
            paymentEmail: observable,
            paymentDescription: observable,
            paymentSku: observable
        });
        this.getPaymentIssuers();
    }

    async getPaymentMethods() {
        let listMethods;

        try {
            const returnData = await payments.getPaymentMethods();
            runInAction(() => {
                listMethods = returnData.data;
                const banktransfer = {
                    "id":"BANKTRANSFER",
                    "description":"Bankoverschrijving"
                };
                listMethods.push(banktransfer);
                this.listMethods = listMethods;
                
                console.log('SbC methods:', listMethods)
                return listMethods;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }

        return listMethods;
    }

    async getPaymentIssuers() {
        let listIssuers;

        try {
            const returnData = await payments.getPaymentIssuers();
            runInAction(() => {
                this.listIssuers = returnData.data;
                listIssuers = returnData.data;
                return listIssuers;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }

        return listIssuers;
    }

    async getPaymentUrl(orderId) {
        let url;

        const data = {
            customer: this.paymentName,
            email: this.paymentEmail,
            issuer: this.paymentIssuer,
            description: this.paymentDescription,
            amount: this.paymentAmount,
            sku: this.paymentSku
        }

        try {
            const returnData = await payments.getPaymentUrl(this.paymentSku, data);
            runInAction(() => {
                this.paymentUrl = returnData.data;
                url = returnData.data;
                return url;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }

        return url;
    }

    setSelectedMethod(value) {
        console.log('SbC method', value)
        this.paymentMethod = value;
    }

    setSelectedIssuer(value) {
        this.paymentIssuer = value;
    }

    setPaymentAmount(value) {
        this.paymentAmount = value;
    }

    setPaymentName(value) {
        this.paymentName = value;
    }

    setPaymentEmail(value) {
        this.paymentEmail = value;
    }

    setPaymentSku(value) {
        this.paymentSku = value;
    }

    setPaymentDescription(type, name) {
        if (type === 'donate') {
            this.paymentDescription = 'Donatie Feelgood Vuurwerk voor: ' + name;
        }
        if (type === 'sleepout') {
            this.paymentDescription = 'Bijdrage deelname sleepout Feelgood Vuurwerk';
        }
        if (type === 'order') {
            this.paymentDescription = 'Bestelling van: ' + name;
        }
    }

    updatePayment = async(type, sku) => {
        let result;
        try {
            const returnData = await payments.updatePayment(type, sku);
            runInAction(() => {
                result = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
    }

    sendEmailPurchase = async(type, sku) => {
        let result;

        try {
            const returnData = await payments.sendEmailPurchase(sku);
            runInAction(() => {
                console.log('SbC return purchase', returnData)
                this.form_feedback = returnData.msg;
                //this.openDialogFeedbackForm('letmeknow');
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
    }

}

export default StorePayments;