import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import * as utilities from '../../utilities/formats';
import { IEView } from 'react-device-detect';

const IndexProducts = inject("stores") (
    observer (
        class IndexProducts extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.state = {
                    content: [],
                    products: []
                }
            }

            componentDidMount = () => {

            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.content !== this.props.content) {
                    const content = this.props.content;
                    console.log('SbC content', content)
                    this.setState({
                        content: content
                    })
                }
            }

            render() {
                return (
                    <div className="index--productcs">
                        {(this.state.content && this.state.content[0] && this.state.content[0].title) &&
                            <ul>
                            {this.state.content.map((content, idx) => (
                                <li>
                                    <ul>
                                        <li>
                                            {content.title.rendered}
                                        </li>
                                        <li>Winterkruidenmix</li>
                                        {this.state.products.map((product, idx) => (
                                            <li>xx</li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                            </ul>
                        }
                    </div>
                )
            }
        }
    )
)

export default IndexProducts;

