import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import queryString from 'query-string';

import Template from '../../templates/template';

const PaymentError = inject("stores") (
    observer (
        class PaymentError extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.env = process.env.REACT_APP_ENV;
                this.urlAPI = process.env.REACT_APP_URL_API;
 

                this.state = {

                }                          
            }

            componentDidMount = async() => {

            }

            handleScroll = () => {
                window.scrollTo(0, 0);
            }

            gotoPage = (url) => {
                this.props.history.push(url);
            }

            render() {
                return (
                    <Template
                        active = {'payment'}
                    >                       
                        <div className="page-content page-content--cart">
                        <div className="segment--content">
                                <div className="segment--content__columns">
                                    <div className="segment--content__column">
                            ER IS EEN FOUT OPGETREDEN

                            <button 
                                className="btn btn--primary"
                                onClick={() => this.gotoPage('/products')}
                            >
                                Ga terug naar producten
                            </button>
                        </div>
                        </div>
                                </div>
                            </div>
                    </Template>
                );
            }
        }
    )
)

export default PaymentError;
