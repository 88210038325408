import * as apifetch from './fetch.js';

export async function getPaymentMethods() {
    const urlEndpoint = 'payment/methods';

    return apifetch.kkFetch(urlEndpoint, 'GET');
}

export async function getPaymentIssuers() {
    const urlEndpoint = 'payment/issuers';

    return apifetch.kkFetch(urlEndpoint, 'GET');
}

export async function getPaymentUrl(orderId, data) {
    const urlEndpoint = 'payment/' + orderId + '/paymentlink';

    const postData = data;

    return apifetch.kkFetch(urlEndpoint, 'POST', postData);
}

export async function updatePayment(type, orderId) {
    const urlEndpoint = 'payment/' + orderId + '/update';

    const postData = {
        type: type
    }

    return apifetch.kkFetch(urlEndpoint, 'POST', postData);
}

export async function sendEmailPurchase(sku) {
    const urlEndpoint = 'email/purchase/' + sku;

    return apifetch.kkFetch(urlEndpoint, 'GET');
}