import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import validate from '../../utilities/validation-rules';
import TextArea from '../../elements/form-input-textarea';

const RowTextArea = inject("stores") (
    observer (
        class RowTextArea extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    formControls: { 
                        textarea: {
                            label: this.props.label,
                            value: this.props.value,
                            placeholder: this.props.placeholder ? this.props.placeholder : i18n.t("forms.placeholder." + this.props.name),
                            validationRules: {
                                isRequired: this.props.required
                            },
                            required: this.props.required
                        }
                    },
                    str_textarea: this.props.maxLength
                }
            }

            componentDidMount = () => {

            }

            handleChange = (event, action) => {     
                const name = event.target.name;
                const value = event.target.value;
          
                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };

                updatedFormElement.value = value;
                if (this.state.formControls[name].value === "") {
                    updatedFormElement.touched = false;
                } else {
                    updatedFormElement.touched = true;
                }              
                updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
                updatedControls[name] = updatedFormElement;
                
                this.setState({
                    formControls: updatedControls,
                }); 

                this.calcRemaining('str_textarea', value, this.props.maxLength);
                this.props.onValidate(this.props.name, value, updatedFormElement.valid);
            }

            calcRemaining(name, str, max) {
                const remaining = max - str.length;
                this.setState({
                    [name]: remaining
                })
            }

            render() {
                const objRules = this.props.validationRules;
                const required = (Object.keys(objRules).length > 0 && 'isRequired' in objRules  && 'isRequired' in objRules)
                    ?   ' --required'
                    :   ''

                return (
                    <div className="form-columns">
                        <div className="form-column">
                            <label className={"form-label" + required}>
                                {i18n.t("forms.label." + this.props.name)}
                            </label>
                        </div>
                        <div className="form__column">
                            <TextArea 
                                name="textarea"
                                maxLength = {this.props.maxLength}
                                placeholder = {i18n.t("forms.placeholder." + this.props.name)}
                                value = {this.state.formControls.textarea.value}
                                onChange = {this.handleChange}
                                valid = {this.state.formControls.textarea.valid}
                            />
                            <div className="form-column--feedback">
                                <span>
                                    {i18n.t("forms.validations.count", {count: this.state.str_textarea})}
                                </span>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(RowTextArea);
