import React from 'react';

const RadioIssuer = props => {

    let formControl = "form-control";
    let direction = "--" + props.direction;
    let urlAPI = process.env.REACT_APP_URL_API;

    return (
        <div className={"form-group form-group--radio " + direction}>
            <ul className="payment-issuers__list">
                {props.options.map(option => (
                    <li className={"payment-issuers__listitem --link" + (parseInt(props.selected) === parseInt(option.id) ? " --selected" : '')} key={option.id}>
                        <label>
                            <input type="radio"
                                name = {props.name}
                                value = {option.id}
                                onChange = {props.onChange}
                                className = {formControl}
                                defaultChecked = {props.defaultValue === option.id}
                            />
                            <img src={urlAPI + "public/images/payments/issuers/"+ option.id + ".png"} />
                            <span>{option.displayValue}</span>
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default RadioIssuer;