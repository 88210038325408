import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import FormTextIncomplete from '../../elements/form-text-incomplete';
import RowText from '../form-rows/row-text';
import RowTextArea from '../form-rows/row-textarea';

const FormReview = inject("stores") (
    observer (
        class FormReview extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeForms = this.props.stores.storeForms;

                this.state = {
                    
                }
            }

            componentWillUnmount = () => {
                //this.storeUi.clearFormFeedback();
            }

            componentDidMount = () => {
                this.initForm();
            }

            initForm = () => {
                
            }

            validateRow = (name, value, valid) => {        
                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);

                this.setState({
                    formControls: updatedControls
                }, () => this.validateForm()); 
            }

            validateForm = () => {
                const formIsValid = this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    formIsValid: formIsValid,
                    feedback: false,
                    btn_class: formIsValid ? '--active' : '--disabled'
                });
            }

            sendFormContact = (e) => {
                e.preventDefault();

                if (this.state.formIsValid) {
                    this.storeForms.sendFormContact(this.state.formControls);
                    this.afterSubmit('disable');
                }
            }

            afterSubmit = (action) => {  
                if (action === 'disable') {
                    this.setState({
                        formIsValid: false,
                        formIsSubmitted: true,
                        btn_class: '--disabled',
                        feedback: true
                    }); 
                }
            } 

            render() {
                return (
                    <div className='products-review'>
                        <div className='products-review__columns'>
                            <div className='products-review__column'>
                                <span className='products-review__column-header'>
                                    Product
                                </span>
                            </div>
                            <div className='products-review__column'>
                                <span className='products-review__column-header'>
                                    Aantal
                                </span>
                            </div>
                            <div className='products-review__column'>
                                <span className='products-review__column-header'>
                                    Prijs
                                </span>
                            </div>
                            <div className='products-review__column'>
                                <span className='products-review__column-header'>
                                    Totaal
                                </span>
                            </div>
                        </div>

                        <div className='products-review__columns'>
                            <div className='products-review__column'>
                                x
                            </div>
                            <div className='products-review__column'>
                                x
                            </div>
                            <div className='products-review__column'>
                                x
                            </div>
                            <div className='products-review__column'>
                                x
                            </div>
                        </div>

                        <div className='products-review__columns'>
                            <div className='products-review__column'>
                                <span>Subtotaal</span>
                                <span>Verzendkosten</span>
                                <span>BTW</span>
                                <span>Totaal</span>
                            </div>
                            <div className='products-review__column'>
                                
                            </div>
                            <div className='products-review__column'>
                               
                            </div>
                            <div className='products-review__column'>
                                <div className='products-review__column-header'>
                                    x
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(FormReview);
