import React from 'react';

const RadioMethod = props => {

    let formControl = "form-control";
    let direction = "--" + props.direction;
    let urlAPI = process.env.REACT_APP_URL_API;

    return (
        <div className={"form-group form-group--radio " + direction}>
            <ul className="payment-methods__list">
                {props.options.map(option => (
                    <li 
                        className = {"payment-methods__listitem --link" + (props.selected === option.id ? " --selected" : '')} 
                        key = {option.id} 
                        onClick = {(e) => props.onChange(e)}
                    >
                        <label>
                            <input 
                                type = "radio"
                                name = {props.name}
                                value = {option.id}
                                onChange = {props.onChange}
                                className = {formControl}
                                defaultChecked = {props.defaultValue === option.id ? 'defaultChecked' : null}
                            />
                            {option.id !== 'BANKTRANSFER' &&
                                <React.Fragment>
                                    <img src={urlAPI + "public/images/payments/methods/"+ option.id + ".png"} />
                                    <span>{option.displayValue}</span>
                                </React.Fragment>
                            }
                            {option.id === 'BANKTRANSFER' &&
                               <span>Bankoverschrijving</span>
                            }
                             
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default RadioMethod;