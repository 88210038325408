import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import Template from '../../templates/template';

import FormAddress from '../../components/forms/form-address';
import FormReview from '../../components/forms/form-review';

const CartReview = inject("stores") (
    observer (
        class CartReview extends Component {

            constructor(props) {
                super(props);
            }

            componentDidMount = async () => {
                window.scroll(0,0);
            }

            componentDidUpdate = async (prevProps) => {
            }

            gotoPage = (url) => {
                this.props.history.push(url);
            }

            render() {
                return (
                    <Template
                        title = {"Controleer je bestelling en vul je gegevens in"}
                    >
                        <div className="page-content page-content--cart">
                            <div className="segment--content">
                                <div className="segment--content__columns">
                                    <div className="segment--content__column">
                                        <h1>Controleer je bestelling</h1>
                                        <FormReview 

                                        />
                                        <h1>Vul je adresgegevens in</h1>
                                        <FormAddress

                                        />
                                        <button 
                                            className="btn btn--primary"
                                            onClick={() => this.gotoPage('/payment')}
                                        >
                                            Ga naar betaling
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default CartReview;

