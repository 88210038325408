import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import Template from '../templates/template';

const AppStart = inject("stores") (
    observer (
        class AppStart extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeContent = this.props.stores.storeContent;

                this.state = {
                    content: []
                };
            }

            componentDidMount = async () => {
                window.scroll(0,0);
                let idx;
                let idxs = [10, 11, 12];
                let content = [];

                let key = 27;
                let i = 0;
                content[i] = await this.storeContent.getContentBlock('pages', key);

                let requests = await Promise.all(idxs.map(async(key, i) => {
                    content[i+1] = await this.storeContent.getContentBlock('pageblocks', key);
                }))

                this.setState({
                    content: content
                })
            }

            componentDidUpdate = async (prevProps) => {
            }

            gotoPage = (url) => {
                this.props.history.push(url);
            }

            render() {
                return (
                    <Template
                        title = {this.state.content && this.state.content[0] &&  this.state.content[0].title.rendered}
                    >
                        <div className="page-content page-content--home">
                            <div className="segment--content">
                                <div className="segment--content__columns">
                                    <div className="segment--content__column">
                                        {this.state.content && this.state.content.map((item, i) => (
                                            i === 1 &&
                                                <React.Fragment>
                                                    {item && item.title && (
                                                        <div 
                                                            className="content__header"
                                                            dangerouslySetInnerHTML={{ __html: this.state.content[i].title.rendered}}
                                                        />
                                                        )
                                                    }
                                                    {this.state.content[i] && this.state.content[i].content &&
                                                        <div 
                                                            className="content__text" 
                                                            dangerouslySetInnerHTML={{ __html: this.state.content[i].content.rendered}}
                                                        />
                                                    }
                                                </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="segment--content segment--content__images">
                                <div 
                                    className="segment--content__image"
                                    style= {{backgroundImage:`url(${this.urlAPI + "public/images/process/process-1.jpg"})` }}
                                />
                                <div 
                                    className="segment--content__image"
                                    style= {{backgroundImage:`url(${this.urlAPI + "public/images/process/process-2.jpg"})` }}
                                />
                                <div 
                                    className="segment--content__quote"
                                    //style= {{backgroundImage:`url(${this.urlAPI + "public/images/process/process-3.jpg"})` }}
                                >
                                    <span>Een gezonde bodem is belangrijk voor het welzijn van een plant</span>
                                </div>
                                <div 
                                    className="segment--content__image"
                                    style= {{backgroundImage:`url(${this.urlAPI + "public/images/process/process-4.jpg"})` }}
                                />
                            </div>

                            <div className="segment--content">
                                <div className="segment--content__columns">
                                    <div className="segment--content__column">
                                        {this.state.content && this.state.content.map((item, i) => (
                                            i > 1 &&
                                                <React.Fragment>
                                                    {item && item.title && (
                                                        <div 
                                                            className="content__header"
                                                            dangerouslySetInnerHTML={{ __html: this.state.content[i].title.rendered}}
                                                        />
                                                        )
                                                    }
                                                    {this.state.content[i] && this.state.content[i].content &&
                                                        <div 
                                                            className="content__text" 
                                                            dangerouslySetInnerHTML={{ __html: this.state.content[i].content.rendered}}
                                                        />
                                                    }
                                                </React.Fragment>
                                        ))}

                                        <div className="content__actions">
                                            <span 
                                                className="btn btn--primary --focus --link"
                                                onClick={() => this.gotoPage('/products')}
                                            >
                                                Ga naar producten
                                            </span>
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default AppStart;

