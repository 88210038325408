import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Swiper, Slide } from 'react-dynamic-swiper';

import NavigationMain from '../components/header/navigation-main';
import Branding from '../components/header/branding';

const Template = inject("stores") (
    observer (
        class Template extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeContent = this.props.stores.storeContent;
                this.state = {};
            }

            componentDidMount = async() => {
                const backgrounds = await this.storeContent.getBackgrounds();

                this.setState({
                    backgrounds: backgrounds
                })
            }

            componentDidUpdate = async(prevProps) => {
            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="segment--content-header">
                            <h1
                                dangerouslySetInnerHTML={{ __html: this.props.title}}
                            />
                        </div>
                        <div className="page-wrapper">
                            <div className="page-content__branding">
                                <Branding />

                                <NavigationMain />
                            </div>

                            {this.props.children}
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default Template;
