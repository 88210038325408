import { makeAutoObservable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as products from '../communicator/products';

class StoreProducts {
    
    constructor() {

    }

    async getJsonProducts() {
        let listProducts = [];
        try {
            const returnData = await products.getJsonProducts();
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    listProducts = returnData.data;
                } 
                return listProducts;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return listProducts;
    }

    
}

export default StoreProducts;