import React , { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import './assets/css/krachtkruid.css';

import App from './app';
import StoreRoot from './stores/store-root';

import * as serviceWorker from './serviceWorker';
import Loader from './elements/loader';

const startApp = () => {
    let storeRoot = new StoreRoot();

    ReactDOM.render(
        <Suspense fallback={<Loader />}> 
            <I18nextProvider i18n={i18n}>
                <Provider stores={storeRoot}>
                    <BrowserRouter basename="/">
                        <App />
                    </BrowserRouter>
                </Provider>
            </I18nextProvider>
        </Suspense>,
        document.getElementById('root')
    );
}

if (!window.cordova) {
    startApp()
} else {
    document.addEventListener('deviceready', startApp, false)
}

serviceWorker.unregister();