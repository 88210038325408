import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import FormTextIncomplete from '../../elements/form-text-incomplete';
import RowText from '../form-rows/row-text';
import RowTextArea from '../form-rows/row-textarea';

const FormQuote = inject("stores") (
    observer (
        class FormQuote extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeForms = this.props.stores.storeForms;
                this.storeProducts = this.props.stores.storeProducts;

                this.state = {
                    formControls: { 
                        fullname: {
                            type: "text",
                            label: i18n.t("forms.label.fullname"),
                            value: '',
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },         
                        email: {
                            type: "email",
                            label: i18n.t("forms.label.email"),
                            value: '',
                            validationRules: {
                                isRequired: true, 
                                isEmail: true
                            },
                            valid: false
                        },
                        phonenr: {
                            type: "text",
                            label: i18n.t("forms.label.phonenr"),
                            value: '',
                            validationRules: {
                                isPhoneNumber: true
                            },
                            valid: false
                        },
                        question: {
                            type: "textarea",
                            label: i18n.t("forms.label.question"),
                            //placeholder: i18n.t("forms.placeholder.question", {maxlength: this.storeUi.settings_list["maxLengthProfile"]}),
                            value: '',
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },
                        calculation: {
                            type: "text",
                            label: i18n.t("forms.label.calculation"),
                            value: '',
                            validationRules: {
                                isRequired: true,
                                passCaptcha: true
                            },
                            valid: false
                        }, 
                    },
                    formIsValid: false,
                    formIsSubmitted: false,
                    btn_class: '--disabled',
                    error: false,
                    feedback: false
                }
            }

            componentWillUnmount = () => {
                //this.storeUi.clearFormFeedback();
            }

            componentDidMount = () => {
                this.initForm();
            }

            initForm = async() => {
                const products = await this.storeProducts.getJsonProducts();
                
                this.setState({
                    products: products
                })
            }

            validateRow = (name, value, valid) => {        
                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);

                this.setState({
                    formControls: updatedControls
                }, () => this.validateForm()); 
            }

            validateForm = () => {
                const formIsValid = this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    formIsValid: formIsValid,
                    feedback: false,
                    btn_class: formIsValid ? '--active' : '--disabled'
                });
            }

            sendFormContact = (e) => {
                e.preventDefault();

                if (this.state.formIsValid) {
                    this.storeForms.sendFormContact(this.state.formControls);
                    this.afterSubmit('disable');
                }
            }

            afterSubmit = (action) => {  
                if (action === 'disable') {
                    this.setState({
                        formIsValid: false,
                        formIsSubmitted: true,
                        btn_class: '--disabled',
                        feedback: true
                    }); 
                }
            } 

            render() {
                return (
                    <div>
                       <form className="form form--contact">
                            <div className="form__columns">
                                <div className="form__column">
                                    {Object.keys(this.state.formControls).map((key, i) => (
                                        (this.state.formControls[key].type === "text" || this.state.formControls[key].type === "email") && key !== 'calculation' &&
                                            <RowText 
                                                type = {this.state.formControls[key].type}
                                                name = {key}
                                                label = {this.state.formControls[key].label}
                                                value = {this.state.formControls[key].value}
                                                validationRules = {this.state.formControls[key].validationRules}
                                                onValidate = {this.validateRow}
                                                style = {'inverted'}
                                                lng = {this.storeUi.app_interface.lang}
                                            />
                                    ))}   
                                
                                    {Object.keys(this.state.formControls).map((key, i) => (
                                        this.state.formControls[key].type === "textarea" &&
                                            <RowTextArea 
                                                name = {key}
                                                label = {this.state.formControls[key].label}
                                                value = {this.state.formControls[key].value}
                                                //placeholder = {i18n.t("forms.placeholder.question", {count: parseInt(this.storeUi.settings_list["maxLengthProfile"])})}
                                                //maxLength = {this.storeUi.settings_list["maxLengthProfile"]}
                                                validationRules = {this.state.formControls[key].validationRules}
                                                onValidate = {this.validateRow}
                                            />
                                    ))}   

                                    {Object.keys(this.state.formControls).map((key, i) => (
                                        key === 'calculation' &&
                                            <RowText 
                                                type = {this.state.formControls[key].type}
                                                name = {key}
                                                label = {this.state.formControls[key].label}
                                                value = {this.state.formControls[key].value}
                                                validationRules = {this.state.formControls[key].validationRules}
                                                onValidate = {this.validateRow}
                                                style = {'inverted'}
                                                lng = {this.storeUi.app_interface.lang}
                                            />
                                    ))}    
                                </div>

                                <div className="form__column">
                                    Ik ben geinteresseerd in de volgende producten:
                                    {this.state.products && this.state.products.map((product) => (
                                        <span>{product.displayValue}</span>
                                    ))
                                    }
                                </div>
                            </div>
                                
                            {this.state.btn_class !== '--active' && !this.state.formIsSubmitted &&
                                <FormTextIncomplete 
                                    lng = {this.storeUi.app_interface.lang}
                                />
                            }
                        </form>

                        <div className="form-row form-row--buttons --contact">
                            <button 
                                className={"btn btn--primary " + this.state.btn_class} 
                                onClick={(e) => this.sendFormContact(e)}>
                                    {i18n.t("button.submit")}
                            </button>
                        </div>

                        {this.state.error &&
                            <div className="form-row form-row--error">
                                <p>{i18n.t("errors.error_send")}</p>
                            </div>
                        }
                        
                        {this.state.feedback &&
                            <div 
                                className="form-row form-row--feedback"
                                dangerouslySetInnerHTML={{ __html: i18n.t("forms.feedback.success")}} 
                            />
                        }
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(FormQuote);
