import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Switch, Route } from 'react-router-dom';
import {
    isBrowser,
    isMobile
} from "react-device-detect";
import ReactGA from 'react-ga';

import TemplateApp from './templates/template-app';

import About from './pages/about';
import Products from './pages/shop/products';
import Start from './pages/start';
import Contact from './pages/contact';
import Quote from './pages/quote';

import Review from './pages/shop/cart-review';
import Payment from './pages/shop/payment';
import PaymentResult from './pages/shop/payment-result';
import PaymentError from './pages/shop/payment-error';

const App = inject("stores") (
observer (
    class App extends Component {
        constructor(props) {
            super(props);
            this.props = props;

            this.storeUi = this.props.stores.storeUi;
        }

        componentDidMount = () => {
        }

        render() {
            return (
                <React.Fragment>
                    <div className={"app-content " + (isMobile ? "app-content--mobile" : "app-content--desktop")}>
                        <TemplateApp>
                            <Switch>
                                <Route exact path='/' component={Start}/>
                                <Route 
                                    path='/products' 
                                    component={Products}
                                />
                                <Route 
                                    path='/about' 
                                    component={About}
                                />
                                <Route 
                                    path='/contact' 
                                    component={Contact}
                                />
                                <Route 
                                    path='/quote' 
                                    component={Quote}
                                />
                                <Route 
                                    path='/review' 
                                    component={Review}
                                />
                                <Route 
                                    path='/payment' 
                                    component={Payment}
                                />
                                <Route 
                                    path='/payment-result' 
                                    component={PaymentResult}
                                />
                                <Route 
                                    path='/payment-error' 
                                    component={PaymentError}
                                />
                            </Switch>
                        </TemplateApp>
                    </div>
                </React.Fragment>
            );
        }
    }
)
)

export default App;
