import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import Template from '../templates/template';
import FormContact from '../components/forms/form-contact';

const Contact = inject("stores") (
    observer (
        class Contact extends Component {

            constructor(props) {
                super(props);
                this.storeContent = this.props.stores.storeContent;

                this.state = {
                    content: []
                };
            }

            componentDidMount = async () => {
                window.scroll(0,0);
                let idx;
                let content = [];

                let key = 25;
                let i = 0;
                content[i] = await this.storeContent.getContentBlock('pages', key);
               
                this.setState({
                    content: content
                })
            }

            componentDidUpdate = async (prevProps) => {
            }

            render() {
                return (
                    <Template
                        title = {this.state.content && this.state.content[0] &&  this.state.content[0].title.rendered}
                    >   
                        <div className="page-content page-content--contact">
                            {this.state.content[0] && this.state.content[0].content &&
                                <div className="segment--content">
                                    <div className="segment--content__columns">
                                        <div className="segment--content__column">
                                            <div 
                                                className="content__text" 
                                                dangerouslySetInnerHTML={{ __html: this.state.content[0].content.rendered}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="segment--content">
                                <FormContact

                                />
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default Contact;

