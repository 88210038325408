import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import * as utilities from '../../utilities/formats';
import { IEView } from 'react-device-detect';

const ListProduct = inject("stores") (
    observer (
        class ListProduct extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.state = {
                    products: []
                }
            }

            componentDidMount = () => {
                this.setState({
                    type: this.props.type
                })
            }

            componentDidUpdate = async (prevProps) => {
                
            }

            render() {
                return (
                    <div className="list-products__listitem">
                        <div>
                            <span 
                                className="product__header"
                            >
                                {this.props.product.displayValue}
                            </span>
                            <ul className="product__summary">
                                {this.props.product.description &&
                                    <li className="product__description">
                                        <span 
                                            dangerouslySetInnerHTML={{ __html: this.props.product.description}}
                                        />
                                    </li>
                                }
                            </ul>
                        </div>
                        <div className="product__actions">
                            <ul className="product__summary">
                                <li className="product__specs">
                                    <div className="product__specs--price">
                                        <span 
                                            className="product__subheader"
                                        >
                                            Prijs:
                                        </span>
                                        <span className="product__price">
                                            {utilities.displayPrice(this.props.product.price)}
                                        </span>
                                    </div>
                                    
                                    <div
                                        className="product__specs--ingredients"
                                    >
                                        <span 
                                            className="product__subheader"
                                        >
                                            Bevat:
                                        </span>
                                        <span>
                                            {this.props.product.ingredients}
                                        </span>
                                    </div>
                                    
                                    <div 
                                        className="product__specs--contents"
                                    >
                                        <span 
                                            className="product__subheader"
                                        >
                                            Inhoud:
                                        </span>
                                        <span>
                                            {this.props.product.contents}
                                        </span>
                                    </div>

                                    <div 
                                        className="product__specs--packaging"
                                    >
                                        <span 
                                            className="product__subheader"
                                        >
                                            Verpakking:
                                        </span>
                                        <img 
                                            src={this.urlAPI + "public/images/icons/"+ this.props.product.container + ".png"} 
                                        />
                                     </div>
                                </li>
                            </ul>
                        
                            {/* <div className="product__action-buttons">
                                <span>Aantal</span>
                                <button
                                    className="btn btn--primary">
                                    Voeg toe aan bestelling
                                </button>
                            </div> */}
                        </div>
                    </div>
                       
                )
            }
        }
    )
)

export default ListProduct;

