export function isInteger(x) {
    return !isNaN(x);
}

export function formatPrice(price) {
    const formattedPrice = (Math.round(price * 100) / 100).toFixed(2);
    return formattedPrice;
}

export function displayPrice(price) {
    const displayedPrice = parseFloat(price)
        .toFixed(2)
        .toString()
        .replace('.',',');
    return displayedPrice;
}

export function capitalizeFirstChar(str) {
    let str2 = '';
    if (str !== null && str !== undefined) {
        str2 = str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str2;
}