import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import Template from '../../templates/template';
import FormPayment from '../../components/forms/form-payment';

const Payment = inject("stores") (
    observer (
        class Payment extends Component {

            constructor(props) {
                super(props);
            }

            componentDidMount = async () => {
                window.scroll(0,0);
            }

            componentDidUpdate = async (prevProps) => {
            }

            gotoPage = (url) => {
                this.props.history.push(url);
            }

            render() {
                return (
                    <Template
                        title = {"Kruidenmixen ondersteunen het welzijn van uw dier"}
                    ><div className="page-content page-content--cart">
                        <div className="segment--content">
                                <div className="segment--content__columns">
                                    <div className="segment--content__column">
                        <FormPayment

                        />

                        <button 
                            className="btn btn--primary"
                            onClick={() => this.gotoPage('/payment-result')}
                        >
                            TEST: betaling geslaagd
                        </button>
                    </div>
                    </div>
                                </div>
                            </div>
                    </Template>
                )
            }
        }
    )
)

export default Payment;

