import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { Redirect } from 'react-router';
import { withRouter } from "react-router-dom";
//import {withRouter} from '../../utilities/withRouter';

import RadioMethod from '../../elements/form-input-radio-method';
import RadioIssuer from '../../elements/form-input-radio-issuer';

const FormPayment = inject("stores") (
    observer (
        class FormPayment extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                // this.storeUser = this.props.stores.storeUser;
                // this.storeOrder = this.props.stores.storeOrder;
                this.storePayments = this.props.stores.storePayments;

                this.state = {
                    btn_submit: "active",
                    redirect: false,
                    redirect_complete: false,
                    methods: [],
                    issuers: [],

                    formControls: { 
                        payment_method: {
                            value: this.props.payment_method_id,
                            placeholder: 'Select your payment method',
                            valid: false,
                            touched: false,
                            options: this.storePayments.listMethods
                        },
                        payment_issuer: {
                            value: '',
                            placeholder: 'Select your bank',
                            valid: false,
                            touched: false,
                            options: this.storePayments.listIssuers
                        }
                    },
                    error: false
                 
                }
            }

            async componentDidMount() {
                let methods;
                let issuers;

                if (this.storePayments.listMethods.length === 0) {
                    methods = await this.storePayments.getPaymentMethods();
                }
                if (this.storePayments.listIssuers.length === 0) {
                    issuers = await this.storePayments.getPaymentIssuers();
                }
                this.setState({
                    methods: methods,
                    issuers: issuers
                })

                console.log('SbC issuers', this.storePayments.listIssuers)
            }

            handleScroll = () => {
                window.scrollTo(0, 0);
            }

            sendConfirmationOrder = () => {

            }

            setCartView = (view) => {
                this.storeOrder.setCartView(view);
                this.handleScroll();
            }

            startPayment = async (payment_id, issuer_code) => {
                if (this.storePayments.paymentIssuer !== '') {
                    const url = await this.storePayments.getPaymentUrl('100001');
                    console.log('SbC link', url)
                    // this.setState({
                    //     redirect: true
                    // })

                    //SbC TEMP
                    
                    //console.log('SbC redirect', url)
                    //const url = '/payment-result';
                    window.location = url;
                    //this.props.navigate(url);
                }
            }

            handleChange = (event) => {
                const name = event.target.name;
                const value = event.target.value;
                let idx;

                console.log('Sbc click', event)

                this.setState({
                    formControls: {
                        ...this.state.formControls,
                        [name]: {
                        ...this.state.formControls[name],
                        value: value
                      }
                    }
                }); 

                if (name === "payment_method") {
                    this.storePayments.setSelectedMethod(value);
                }
                if (name === "payment_issuer") {
                    this.storePayments.setSelectedIssuer(value);
                }
            }

            render() {
                if (this.state.redirect) {
                    return <Redirect 
                        push to={"/payment"} 
                    />;
                }

                return (
                    <React.Fragment>
                         <form>
                            <div className="wrapper--form">
                                <div className="wrapper--form__content">
                                    <div className="form-row form-row--input">
                                        <div className="container-cart__header">
                                            <h1 className="">
                                                {i18n.t("cart.header.payment_method")}
                                            </h1>
                                        </div> 
                                        <RadioMethod 
                                            name = "payment_method" 
                                            placeholder = {this.state.formControls.payment_method.placeholder}
                                            value = {this.state.formControls.payment_method.value}
                                            options = {this.storePayments.listMethods}
                                            direction = {"horizontal"}
                                            onChange = {this.handleChange}
                                            defaultValue = {this.props.payment_method_id}
                                            selected = {this.storePayments.paymentMethod}
                                        />
                                    </div>
                                
                                    {(this.state.formControls.payment_method.value === 'IDEAL') &&
                                    <React.Fragment>
                                        <div className="form-row form-row--input">
                                            <div className="container-cart__header">
                                                <h1 className="">
                                                    {i18n.t("cart.header.payment_issuer")}
                                                </h1>
                                            </div> 
                                            <RadioIssuer 
                                                name = "payment_issuer" 
                                                placeholder = {this.state.formControls.payment_issuer.placeholder}
                                                value = {this.state.formControls.payment_issuer.value}
                                                options = {this.storePayments.listIssuers}
                                                direction = {"horizontal"}
                                                onChange = {this.handleChange}
                                                //defaultValue = {this.state.formControls.payment_issuer.defaultValue}
                                                selected = {this.storePayments.paymentIssuer}
                                            />
                                        </div>
                                    </React.Fragment>
                                    }

                                    {this.state.error &&
                                        <div className="form-row form-row--error">
                                            <p>Er is een fout opgetreden. Controleer je invoergegevens.</p>
                                        </div>
                                    }
                                
                                    <div className="form-row form-row__buttons">
                                        <span 
                                            className="btn btn--primary --link"
                                            onClick={() => this.startPayment()}
                                        >
                                            Betaal nu!
                                        </span>
                                    </div>
                                    </div>
                            </div>
                        </form>
                            
                        
                       
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(FormPayment);
