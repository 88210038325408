import { makeAutoObservable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';

class StoreUi {
    app_interface = { 
        lang: '',
        lang_id: 0,
        height_footer: 0,
        isDialogContentOpen: false
    }  
    heightBranding = 0
    menu_mobile = false
    content_component = ''
    
    closeDialogContent = () => {
        this.app_interface.isDialogContentOpen = false;
    }

    openDialogContent = () => {
        this.app_interface.isDialogContentOpen = true;
    }

    setContentComponent(comp) {
        this.content_component = comp;
    }
    
    setHeightBranding(num) {
        this.heightBranding = num;
    }

    setMobileMenu() {
        this.menu_mobile = !this.menu_mobile;
    }
}

export default StoreUi;