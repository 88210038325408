export async function getMenus() {
    //const urlAPI = process.env.REACT_APP_URL_API_SHOP;
    const urlAPI = 'http://www.marielleruiters.nl/wp/wp-json/wp-api-menus/v2/';
    const urlEndpoint = urlAPI + 'menus/';

    try {
        let apiStr = urlEndpoint;

        let response = await fetch(apiStr, {
            method: 'GET'
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiReturn;
            if (responseJson !== true && 'error' in responseJson && responseJson.error.length > 0 ) {
                apiReturn =  responseJson.error;
            } else {
                apiReturn =  responseJson;
            }
            return apiReturn;
        }
    } catch (error) {
        console.log('SbC fetch error menus: ', error.message);
    }
}

export async function getMenu(id) {
    //const urlAPI = process.env.REACT_APP_URL_API_SHOP;
    const urlAPI = 'http://www.marielleruiters.nl/wp/wp-json/wp-api-menus/v2/';
    const urlEndpoint = urlAPI + 'menus/' + id;

    try {
        let apiStr = urlEndpoint;

        let response = await fetch(apiStr, {
            method: 'GET'
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiReturn;
            if (responseJson !== true && 'error' in responseJson && responseJson.error.length > 0 ) {
                apiReturn =  responseJson.error;
            } else {
                apiReturn =  responseJson;
            }
            return apiReturn;
        }
    } catch (error) {
        console.log('SbC fetch error menu: ', error.message);
    }
}

