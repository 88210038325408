import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Swiper, Slide } from 'react-dynamic-swiper';

import NavigationMain from '../components/header/navigation-main';
import Footer from '../components/footer/footer.js';

const TemplateApp = inject("stores") (
    observer (
        class TemplateApp extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeContent = this.props.stores.storeContent;
                this.state = {};
            }

            componentDidMount = async() => {
                const backgrounds = await this.storeContent.getBackgrounds();

                this.setState({
                    backgrounds: backgrounds
                })
            }

            componentDidUpdate = async(prevProps) => {
            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="under-construction__swiper">
                            <Swiper 
                                ref={(c) => { this.reactSwiper = c }}
                                swiperOptions={{
                                    slidesPerView: 1,
                                    autoplay: {
                                        delay: 4000,
                                        disableOnInteraction: false
                                    },
                                    effect: 'fade',
                                    speed: 2500,
                                    freeMode: true,
                                    loopedSlides: 10
                                }}
                                {...this.state.options}
                            >
                                {this.state.backgrounds && this.state.backgrounds.map((img, i) => (
                                    <Slide key={i}>
                                        <div 
                                            className="swiper-slide__img"
                                            style= {{backgroundImage:`url(${this.urlAPI + "public/images/backgrounds/" + img})` }}
                                        >
                                            x
                                        </div>
                                    </Slide>
                                ))}
                            </Swiper> 
                        </div>
                        
                        {this.props.children}

                        <Footer />
                                                    
                    </React.Fragment>
                )
            }
        }
    )
)

export default TemplateApp;
