import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import sizeMe from 'react-sizeme';

const Branding = inject("stores") (
    observer (
        class Branding extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.state = {};
            }

            componentDidMount = async() => {
            }

            componentDidUpdate = async(prevProps) => {
                const { width, height } = this.props.size;
                this.storeUi.setHeightBranding(this.props.size.height - 3);
            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="wrapper--branding">
                            <div className="branding__logo-img">
                                <img src={this.urlAPI + "public/images/branding/logo-image-cropped.png"} />
                            </div>
                            <div className="branding__logo-payoff">
                                <img src={this.urlAPI + "public/images/branding/logo-text-cropped.png"} />
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default sizeMe({ monitorHeight: true })(Branding);