import StoreGeneral from './store-general';
import StoreUi from './store-ui';
import StoreContent from './store-content';
import StorePayments from './store-payments';
import StoreProducts from './store-products';
import StoreForms from './store-forms';

class StoreRoot {

    constructor() {  
        this.storeGeneral = new StoreGeneral(this);
        this.storeUi = new StoreUi(this);
        this.storeContent = new StoreContent(this);
        this.storePayments = new StorePayments(this);
        this.storeProducts = new StoreProducts(this);
        this.storeForms = new StoreForms(this);
    }
    
}

export default StoreRoot;