import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

const Footer = inject("stores") (
    observer (
        class Footer extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
            }

            componentDidMount = async () => {

            }

            componentDidUpdate = async (prevProps) => {
            }

            gotoPage = (url) => {
                window.scrollTo(0,0);
                this.props.history.push(url);
            }

            render() {
                return (
                    <div className="wrapper--footer">
                        <div className="footer-content footer-content__navigation--main">
                            <div className="segment--content">
                                <div className="segment--content__columns">
                                    <div className="segment--content__column">
                                        <ul className="navigation--footer__list">
                                            <li 
                                                className="navigation--footer__listitem navigation--footer__listitem--home --link"
                                                onClick={() => this.gotoPage('/')}
                                            >
                                                <span>Home</span>
                                            </li>
                                            <li 
                                                className="navigation--footer__listitem navigation--footer__listitem--products --link"
                                                onClick={() => this.gotoPage('/products')}
                                            >
                                                <span>Producten</span>
                                            </li>
                                            <li 
                                                className="navigation--footer__listitem navigation--footer__listitem--about --link"
                                                onClick={() => this.gotoPage('/about')}
                                            >
                                                <span>Over Krachtkruid</span>
                                            </li>
                                            <li 
                                                className="navigation--footer__listitem navigation--footer__listitem--about --link"
                                                onClick={() => this.gotoPage('/contact')}
                                            >
                                                <span>Contact</span>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="segment--content__column">
                                        <ul className="navigation--footer__list">
                                            <li 
                                                className="navigation--footer__listitem navigation--footer__listitem--logo"
                                            >
                                                <div className="branding__logo-img">
                                                    <img src={this.urlAPI + "public/images/branding/logo-image-cropped.png"} />
                                                </div>
                                                <div className="branding__logo-payoff">
                                                    <img src={this.urlAPI + "public/images/branding/logo-text-cropped.png"} />
                                                </div>
                                            </li>
                                            <li>-</li>
                                            <li 
                                                className="navigation--footer__listitem"
                                            >
                                                <span>Bergweg 23</span>
                                            </li>
                                            <li 
                                                className="navigation--footer__listitem"
                                            >
                                                <span>Ommen</span>
                                            </li>
                                            <li>-</li>
                                            <li 
                                                className="navigation--footer__listitem"
                                            >
                                                <span>lotte@krachtkruid.nl</span>
                                            </li>
                                            <li 
                                                className="navigation--footer__listitem"
                                            >
                                                <span>KvK: 77414462</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(Footer);

