import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

const NavigationMain = inject("stores") (
    observer (
        class NavigationMain extends Component {

            constructor(props) {
                super(props);
                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount = async () => {
                window.addEventListener('scroll', this.isSticky);
            }

            componentDidUpdate = async (prevProps) => {
            }

            gotoPage = (url) => {
                this.props.history.push(url);
            }

            isSticky = () => {
                const navEl = document.querySelector('.header-content__navigation--main');
                const scrollTop = window.scrollY;

                const posTop = this.storeUi.heightBranding;

                if (scrollTop >= posTop) {
                    navEl.classList.add('--sticky');
                } else {
                    navEl.classList.remove('--sticky');
                }
            }

            //const margin_bottom = this.storeUi.app_interface.height_footer;

            //<div style={{marginBottom: margin_bottom}}>

            render() {
                return (
                    <div className="header-content header-content__navigation--main">
                        <ul className="navigation--main__list">
                            <li 
                                className="navigation--main__listitem navigation--main__listitem--home --link"
                                onClick={() => this.gotoPage('/')}
                            >
                                <span>Home</span>
                            </li>
                            <li 
                                className="navigation--main__listitem navigation--main__listitem--products --link"
                                onClick={() => this.gotoPage('/products')}
                            >
                                <span>Producten</span>
                            </li>
                            <li 
                                className="navigation--main__listitem navigation--main__listitem--about --link"
                                onClick={() => this.gotoPage('/about')}
                            >
                                <span>Over Krachtkruid</span>
                            </li>
                            <li 
                                className="navigation--main__listitem navigation--main__listitem--about --link"
                                onClick={() => this.gotoPage('/contact')}
                            >
                                <span>Contact</span>
                            </li>
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withRouter(NavigationMain);

